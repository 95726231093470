<template>
    <div>
        <h2 class="mb-2">Settings</h2>
        <b-card>
            <h5>Manage Quickbooks Connection</h5>
            <hr>
            <b-button
                variant="outline-secondary"
            >
            Connect to Quickbooks
            </b-button>
        </b-card>
    </div>
</template>

<script>
import { 
    BCard, BCardBody, BButton,
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BCardBody,
        BButton,
    }
}
</script>

<style>

</style>